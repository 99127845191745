//export const APIURL = 'http://localhost:80'
export const ENDPOINT = "https://notifyskylot44.net";
export const APIURL = 'https://apiadminbetg.com'


//export const WEBTOKEN = 'sswinbet'
//export const WEBNAME = 'sswinbet'
//export const WEBFULLNAME = 'SSWINBET'

/*
export const WEBTOKEN = 'nvip'
export const WEBNAME = 'nvip'
export const WEBFULLNAME = 'NEXT168vip'
*/
/*
export const WEBTOKEN = 'dragongold999'
export const WEBNAME = 'dragongold999'
export const WEBFULLNAME = 'dragongold999'
*/

/*
export const WEBTOKEN = 'lottomax789'
export const WEBNAME = 'lottomax789'
export const WEBFULLNAME = 'lottomax789'
*/
/*
export const WEBTOKEN = 'max6969'
export const WEBNAME = 'max6969'
export const WEBFULLNAME = 'max6969'
*/
/*
export const WEBTOKEN = 'gpbet'
export const WEBNAME = 'Gpowerbet'
export const WEBFULLNAME = 'GpowerBetting'
*/
/*
export const WEBTOKEN = 'Pam168'
export const WEBNAME = 'Pam168'
export const WEBFULLNAME = 'Pam168'
*/
/*
export const WEBTOKEN = 'Ben1688'
export const WEBNAME = 'Ben1688'
export const WEBFULLNAME = 'Ben1688'
*/
/*
export const WEBTOKEN = 'richfar888'
export const WEBNAME = 'richfar888'
export const WEBFULLNAME = 'Richfar888'
*/

export const WEBTOKEN = 'User147'
export const WEBNAME = 'user147'
export const WEBFULLNAME = 'user147'

/*
export const WEBTOKEN = 'User95'
export const WEBNAME = 'user95'
export const WEBFULLNAME = 'user95'
*/
/*
export const WEBTOKEN = 'User44'
export const WEBNAME = 'user44'
export const WEBFULLNAME = 'User44'
*/
/*
export const WEBTOKEN = 'PananVIP'
export const WEBNAME = 'PananVIP'
export const WEBFULLNAME = 'PananVIP'
*/
/*
export const WEBTOKEN = 'League88'
export const WEBNAME = 'League88'
export const WEBFULLNAME = 'League88'
*/
// For Test
/*
export const WEBTOKEN = 'bwin77'
export const WEBNAME = 'bwin77'
export const WEBFULLNAME = 'GpowerBetting'
*/

/*
export const WEBTOKEN = 'Gdiamond88'
export const WEBNAME = 'Gdiamond88'
export const WEBFULLNAME = 'Gdiamond88'
*/
/*
export const WEBTOKEN = 'Grand369'
export const WEBNAME = 'Grand369'
export const WEBFULLNAME = 'Grand369'
*/
/*
export const WEBTOKEN = 'Return24h'
export const WEBNAME = 'Return24h'
export const WEBFULLNAME = 'Return24h'
*/
/*
export const WEBTOKEN = 'Sbo95th'
export const WEBNAME = 'Sbo95th'
export const WEBFULLNAME = 'Sbo95th'
*/
/*
export const WEBTOKEN = 'rainbowbet888'
export const WEBNAME = 'rainbowbet888'
export const WEBFULLNAME = 'rainbowbet888'
*/
//export const WEBTOKEN = 'user55'
//export const WEBNAME = 'user55'

export const PlayGame = 'Yes'
//export const LG = 'thai'
export const LG = 'lao'
export const addCommas = (nStr) => {
    nStr += ""
    const x = nStr.split(".")
    let x1 = x[0]
    const x2 = x.length > 1 ? `.${x[1]}` : ""
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      //x1 = x1.replace(rgx, "$1" + "," + "$2")
      x1 = x1.replace(rgx, `$1,$2`)
    }
    return x1 + x2
    //return nStr
  }

export const checkcurrency = (name) => {
  if(name == 'th') {
    return 'THB'
  } else if(name == 'dolla'){
    return 'USD'
  } else if(name == 'kip') {
    return 'KIP'
  } else {
    return ""
  }
}

export const checkcurrencyamount = (name, rate) => {
  if(name == 'kip') {
    return 1
  } else if(name == 'dolla'){
    return rate.currency_usd
  } else if(name == 'th') {
    return rate.currency_kip
  } else {
    return ""
  }
}


export const getBase64 = (file, cb) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}

export const decodeBase64Image = (base64Str) => {
  const matches = base64Str.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
  const image = {}
  if (!matches || matches.length !== 3) {
    throw new Error("Invalid base64 string")
  }

  image.type = matches[1]
  image.data = matches[2]

  return image
}

/*
TEST-GCLUB
PORT=80
MONGODB_URL=mongodb+srv://ooddoo888:M_Loso2551@cluster0-ousns.mongodb.net/testRCG
SECRET_KEY=abc123
SALT_ROUND=10
API_URL=http://rcgapiv2.dev-royalgame.com
CLIENT_ID=c7813ae4-fbda-4473-8c53-d8f2e094a6d1
CLIENT_SECRET=0912c65e
DES_KEY=d2edbdd0
DES_IV=078e833f
RATELIMIT_TTL=60
RATELIMIT_LIMIT=1000
NATS_URL=nats://139.59.246.30:4223/Rcg
JWT_SECRET=c7813ae4-fbda-4473-8c53-d8f2e094a6d10912c65e
NATS_URL_WALLET=nats://139.59.246.30:4223/user-wallet1680001261
NATS_USER=nats
NATS_PASS=master
*/